import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import PageHeading from './page-heading'
import React from 'react'
import styled from 'styled-components'

export default function ShopHoldingSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicShopHoldingPage {
        data {
          page_heading {
            text
          }
          holding_image_landscape {
            alt
            dimensions {
              width
              height
            }
            url
            small {
              url
            }
            medium {
              url
            }
            large {
              url
            }
            xlarge {
              url
            }
            xxlarge {
              url
            }
          }
          holding_image_portrait {
            alt
            dimensions {
              width
              height
            }
            url
            small {
              url
            }
            medium {
              url
            }
            large {
              url
            }
            xlarge {
              url
            }
            xxlarge {
              url
            }
          }
          max_image_width
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicShopHoldingPage.data.page_heading[0].text,
    landscapeImageAltText: data.prismicShopHoldingPage.data.holding_image_landscape.alt,
    landscapeImageWidth: data.prismicShopHoldingPage.data.holding_image_landscape.dimensions.width,
    landscapeImageHeight: data.prismicShopHoldingPage.data.holding_image_landscape.dimensions.height,
    landscapeImageUrl: data.prismicShopHoldingPage.data.holding_image_landscape.url,
    landscapeImageSmallUrl: data.prismicShopHoldingPage.data.holding_image_landscape.small.url,
    landscapeImageMediumUrl: data.prismicShopHoldingPage.data.holding_image_landscape.medium.url,
    landscapeImageLargeUrl: data.prismicShopHoldingPage.data.holding_image_landscape.large.url,
    landscapeImageXlargeUrl: data.prismicShopHoldingPage.data.holding_image_landscape.xlarge.url,
    landscapeImageXxlargeUrl: data.prismicShopHoldingPage.data.holding_image_landscape.xxlarge.url,
    portraitImageAltText: data.prismicShopHoldingPage.data.holding_image_portrait.alt,
    portraitImageWidth: data.prismicShopHoldingPage.data.holding_image_portrait.dimensions.width,
    portraitImageHeight: data.prismicShopHoldingPage.data.holding_image_portrait.dimensions.height,
    portraitImageUrl: data.prismicShopHoldingPage.data.holding_image_portrait.url,
    portraitImageSmallUrl: data.prismicShopHoldingPage.data.holding_image_portrait.small.url,
    portraitImageMediumUrl: data.prismicShopHoldingPage.data.holding_image_portrait.medium.url,
    portraitImageLargeUrl: data.prismicShopHoldingPage.data.holding_image_portrait.large.url,
    portraitImageXlargeUrl: data.prismicShopHoldingPage.data.holding_image_portrait.xlarge.url,
    portraitImageXxlargeUrl: data.prismicShopHoldingPage.data.holding_image_portrait.xxlarge.url,
    imageIsFullWidth: data.prismicShopHoldingPage.data.max_image_width,
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      {prismicData.pageHeading && (
        <div className="page-heading-wrapper">
          <PageHeading heading={prismicData.pageHeading} />
        </div>
      )}

      <Fade>
        <div
          className={`image-wrapper ${prismicData.imageIsFullWidth ? 'image-wrapper--full-width' : 'image-wrapper--contained'}`}
        >
          <div className="landscape">
            <picture>
              <source media="(min-width: 2000px)" srcSet={prismicData.landscapeImageXxlargeUrl} />
              <source media="(min-width: 1200px)" srcSet={prismicData.landscapeImageXlargeUrl} />
              <source media="(min-width: 600px)" srcSet={prismicData.landscapeImageLargeUrl} />
              <source media="(min-width: 320px)" srcSet={prismicData.landscapeImageMediumUrl} />
              <source media="(min-width: 0px)" srcSet={prismicData.landscapeImageSmallUrl} />
              <img
                src={prismicData.landscapeImageUrl}
                alt={prismicData.landscapeImageAltText}
                width={prismicData.landscapeImageWidth}
                height={prismicData.landscapeImageHeight}
              />
            </picture>
          </div>
          <div className="portrait">
            <picture>
              <source media="(min-width: 2000px)" srcSet={prismicData.portraitImageXxlargeUrl} />
              <source media="(min-width: 1200px)" srcSet={prismicData.portraitImageXlargeUrl} />
              <source media="(min-width: 600px)" srcSet={prismicData.portraitImageLargeUrl} />
              <source media="(min-width: 320px)" srcSet={prismicData.portraitImageMediumUrl} />
              <source media="(min-width: 0px)" srcSet={prismicData.portraitImageSmallUrl} />
              <img
                src={prismicData.portraitImageUrl}
                alt={prismicData.portraitImageAltText}
                width={prismicData.portraitImageWidth}
                height={prismicData.portraitImageHeight}
              />
            </picture>
          </div>
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-bottom: -8rem;

  .page-heading-wrapper {
    margin-top: 2rem;
    margin-bottom: -1rem;

    @media (min-width: 1000px) {
      margin-bottom: -2rem;
    }
  }

  .image-wrapper {
    &--full-width {
      width: 100%;
      max-width: none;
    }

    &--contained {
      width: 100%;
      margin: 0 auto;

      @media (orientation: landscape) {
        max-width: 147rem;
      }

      @media (orientation: portrait) {
        max-width: 82rem;
      }
    }
  }

  .landscape {
    display: none;

    @media (orientation: landscape) {
      display: block;
    }
  }

  .portrait {
    display: none;

    @media (orientation: portrait) {
      display: block;
    }
  }
`
