import {graphql} from 'gatsby'
import ShopHoldingSection from '../components/shop-holding-section'
import Layout from '../components/layout'
import React, {useState} from 'react'
import {SHOP_HOLDING_SECTION_CSS_MODIFIER, HOLDING_PAGE_CSS_MODIFIER, PAGE_ROOT_ELEMENT_ID} from '../constants/global-constants'

export default props => {
  const {data} = props
  const prismicData = {
    showTheHoldingPage: data.prismicHoldingPage.data.show_the_holding_page,
  }

  return (
    <Layout
      documentTitlePrefix="SHOP"
      isIndexPage={false}
      pageName="shop"
      showScrollToTopBtn={false}
      showTheHoldingPage={prismicData.showTheHoldingPage}
    >
      {prismicData.showTheHoldingPage ? (
        <>
          <HoldingPageHeroImage />
          <ContactSection cssModifier={HOLDING_PAGE_CSS_MODIFIER} />
        </>
      ) : (
        <>
          <ShopHoldingSection cssModifier={SHOP_HOLDING_SECTION_CSS_MODIFIER} />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    prismicHoldingPage {
      data {
        show_the_holding_page
      }
    }
  }
`
